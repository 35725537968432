import { FC } from "react"

type FormattedSignRowProps = {
  label?: string
}

const FormattedSignRow: FC<FormattedSignRowProps> = ({ children, label }) => {
  return children ? (
    <div className="flex flex-row w-full pb-3">
      <div className="w-1/2 md:w-1/3">{label}</div>
      <div className="w-1/2 md:w-2/3 font-bold break-words">{children}</div>
    </div>
  ) : null
}

export default FormattedSignRow
