import { FC } from "react"
import format from "date-fns/format"
import { DocumentDetails } from "../../../state/idport"
import FormattedSignRow from "./formatted-sign-row"

type FormattedDocumentSignProps = {
  scope?: DocumentDetails
  data?: unknown
  objectSignPresent?: boolean
}

const FormattedDocumentSign: FC<FormattedDocumentSignProps> = ({
  scope,
  data,
  objectSignPresent = false,
}) => {
  if (!data) {
    return null
  }

  if (scope === "title") {
    const { pageCount, text, uri } = data as {
      uri: string
      text: string
      pageCount: number
    }

    // "noopener noreferrer" is to prevent a type of phishing known as tabnabbing
    return text && uri ? (
      <FormattedSignRow label="Název dokumentu">
        <a
          className="inline-block underline hover:no-underline"
          href={uri}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>{" "}
        {pageCount ? `(${pageCount} stran)` : ""}
      </FormattedSignRow>
    ) : null
  }

  if (scope === "created") {
    const createdDate = format(
      new Date(data as string),
      "dd. MM. yyyy hh:mm:ss"
    )
    return (
      <FormattedSignRow label="Datum vytvoření dokumentu">
        {createdDate}
      </FormattedSignRow>
    )
  }

  if (scope === "author") {
    const author = data as string
    return (
      <>
        <FormattedSignRow label="Autor dokumentu">{author}</FormattedSignRow>
        {/* after  "Autor dokumentu" is always line... why it is needed: it devides info about document and object sign */}
        {objectSignPresent && <hr className="mt-2 mb-4 border-black-da" />}
      </>
    )
  }

  if (scope === "id") {
    const id = data as string
    return <FormattedSignRow label="ID podpisu">{id}</FormattedSignRow>
  }

  if (scope === "signRequestId") {
    const signId = data as string
    return <FormattedSignRow label="ID požadavku">{signId}</FormattedSignRow>
  }

  if (scope === "subject") {
    const subject = data as string
    return (
      <FormattedSignRow label="Popis dokumentu">{subject}</FormattedSignRow>
    )
  }

  if (scope === "documentHash") {
    const hash = data as string
    return (
      <>
        <FormattedSignRow label="Otisk dokumentu">{hash}</FormattedSignRow>
        <FormattedSignRow label="Metoda">SHA512</FormattedSignRow>
      </>
    )
  }

  if (scope === "appName") {
    const appName = data as string
    return <FormattedSignRow label="Název služby">{appName}</FormattedSignRow>
  }

  if (scope === "providerName") {
    const providerName = data as string
    return (
      <FormattedSignRow label="Název obchodníka">
        {providerName}
      </FormattedSignRow>
    )
  }

  return null
}

export default FormattedDocumentSign
