import { FC, useRef } from "react"
import { useRecoilValue } from "recoil"

import { ReactComponent as Info } from "../../assets/infoGreen.svg"
import {
  aDocumentToSign,
  aObjectToSign,
  DocumentDetails,
  ObjectSignDetails,
} from "../../state/idport"
import { ReactComponent as ArrowDown } from "../../assets/circleArrowDown.svg"
import FormattedDocumentSign from "../format/sign/formatted-document-sign"
import FormattedObjectSign from "../format/sign/formatted-object-sign"

interface SortDocumentScopes {
  (firstScope: DocumentDetails, secondScope: DocumentDetails): number
}

const sortDocumentScopes: SortDocumentScopes = (firstScope, secondScope) => {
  const scopeDisplayOrder = {
    providerName: 0,
    title: 1,
    subject: 2,
    documentHash: 3,
    signRequestId: 4,
    created: 5,
    author: 6,
    appName: 7,
    id: 8,
    size: 9,
    read_by_enduser: 10,
    uri: 11,
    page_count: 12,
    language: 13,
  }

  return scopeDisplayOrder[firstScope] - scopeDisplayOrder[secondScope]
}

interface ObjectSignScopes {
  (firstScope: ObjectSignDetails, secondScope: ObjectSignDetails): number
}

const sortObjectSignScopes: ObjectSignScopes = (firstScope, secondScope) => {
  const scopeDisplayOrder = {
    signObjectHash: 0,
    created: 1,
    signRequestId: 2,
    signObject: 3,
  }

  return scopeDisplayOrder[firstScope] - scopeDisplayOrder[secondScope]
}

const BankSign: FC = () => {
  const document = useRecoilValue(aDocumentToSign)
  const objectSign = useRecoilValue(aObjectToSign)
  const documentMap = document
    ? (Object.keys(document) as DocumentDetails[])
    : []
  const objectSignMap = objectSign
    ? (Object.keys(objectSign) as ObjectSignDetails[])
    : []
  const hiddenDivRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <h1 className="text-lg font-bold pb-6 mb-5 border-b border-solid border-black-da">
        Pokyn k elektronickému podpisu
      </h1>
      <div className="overflow-y-auto h-full flex flex-col text-xs mb-2">
        {documentMap.length > 0 &&
          documentMap.sort(sortDocumentScopes).map(scope => {
            return (
              <FormattedDocumentSign
                key={scope}
                scope={scope}
                data={document[scope]}
                objectSignPresent={objectSign !== null}
              />
            )
          })}
        {objectSignMap.length > 0 &&
          objectSignMap.sort(sortObjectSignScopes).map(scope => {
            return (
              <FormattedObjectSign
                key={scope}
                scope={scope}
                data={objectSign[scope]}
              />
            )
          })}
      </div>
      <div className="sm:hidden -mt-12 flex justify-end pb-1 z-10">
        <button
          onClick={() => {
            window.scrollTo({
              top: hiddenDivRef?.current?.offsetTop,
              behavior: "smooth",
            })
          }}
        >
          <ArrowDown />
        </button>
      </div>
      <hr className="mt-2 mb-4 border-black-da" />
      <div className="flex items-center bg-black-f5 py-3 px-2 w-full text-xs rounded-md">
        <Info className="mr-3 w-10" />
        Pokračováním dáváte pokyn Společnosti Bankovní identita, a.s., aby
        připojila váš elektronický podpis
      </div>
      <div ref={hiddenDivRef} />
    </>
  )
}

export default BankSign
