import { FC } from "react"
import format from "date-fns/format"
import { ObjectSignDetails } from "../../../state/idport"
import FormattedSignRow from "./formatted-sign-row"

type FormattedObjectSignProps = {
  scope?: ObjectSignDetails
  data?: unknown
}

const FormattedObjectSign: FC<FormattedObjectSignProps> = ({ scope, data }) => {
  if (!data) {
    return null
  }

  if (scope === "signObject") {
    const signObjectMap = Object.keys(
      data as Partial<Record<string, string>>
    ) as string[]
    const signObject = data as Partial<Record<string, string>>

    return (
      <FormattedSignRow label="Podepisovaný obsah">
        <div className="font-medium">
          {signObjectMap.map(consent => {
            return (
              <p className="pb-3" key={consent}>
                {consent}
                {signObject[consent] && (
                  <span className="inline-block font-bold">
                    &nbsp;- {signObject[consent]}
                  </span>
                )}
              </p>
            )
          })}
        </div>
      </FormattedSignRow>
    )
  }

  if (scope === "signObjectHash") {
    const hashObject = data as string
    return (
      <>
        <FormattedSignRow label="Otisk prohlášení">
          {hashObject}
        </FormattedSignRow>
        <FormattedSignRow label="Metoda">SHA512</FormattedSignRow>
      </>
    )
  }

  if (scope === "signRequestId") {
    const signId = data as string
    return <FormattedSignRow label="ID požadavku">{signId}</FormattedSignRow>
  }

  if (scope === "created") {
    const createdDate = format(
      new Date(data as string),
      "dd. MM. yyyy hh:mm:ss"
    )
    return (
      <FormattedSignRow label="Datum vytvoření">{createdDate}</FormattedSignRow>
    )
  }

  return null
}

export default FormattedObjectSign
