import axios from "axios"
import React, { FC } from "react"
import { FallbackProps } from "react-error-boundary"
import { Redirect } from "react-router-dom"
import { useEffectOnce } from "react-use"
import { useSetRecoilState } from "recoil"
import { aBeErrorData } from "../state/global"

type GlobalError = "general" | "timeout" | "forbidden" | "server"

export type GlobalErrorState = {
  state: {
    errorType: GlobalError
  }
}

const ErrorFallback: FC<FallbackProps> = ({ error }) => {
  const setBeErrorData = useSetRecoilState(aBeErrorData)
  let errorType: GlobalError = "general"

  if (axios.isAxiosError(error)) {
    const statusCode = error.response?.status
    switch (statusCode) {
      case 403:
        errorType = "forbidden"
        break
      case 500:
        errorType = "server"
        break
      case 502:
      case 503:
      case 504:
        errorType = "timeout"
        break
      default:
        errorType = "general"
        break
    }

    if (errorType === "general" && statusCode && statusCode % 500 < 100) {
      errorType = "server"
    }
  }

  useEffectOnce(() => {
    if (axios.isAxiosError(error)) {
      setBeErrorData(error.response?.data)
    }
  })

  return <Redirect to={{ pathname: "/err", state: { errorType } }} />
}

export default ErrorFallback
