/**
 * Selectors in this file are meant to batch update Recoil state since a series
 * of updates would cause useEffect() handlers to trigger after each change.
 * Therefore we batch-update the state to smoothly transition between phases.
 */
import { DefaultValue, selector } from "recoil"

import {
  aBearerToken,
  aOperationType,
  aPhase,
  aPreloginAppName,
  aPreloginProviderName,
  aQrBearerToken,
  BearerToken,
  PhaseState,
  aSignRequestId,
} from "./global"
import { aIbUrl } from "./auxiliary"
import {
  aLoginPhaseError,
  aLoginStep,
  aRedirectUrl,
  LoginPhaseError,
} from "./login-step"
import {
  aDocumentToSign,
  aObjectToSign,
  DocumentToSign,
  ObjectToSign,
} from "./idport"

type ProcessPreLoginSelector = {
  apaInitAuthToken: BearerToken
  appName: string
  providerName: string
  redirectUrl: string
  operationType: "AUTHENTICATION" | "DOCUMENT_SIGN" | ""
  document: DocumentToSign
  signObject: ObjectToSign
  signRequestId: string
}

type DocumentTitle = {
  uri?: string | unknown
  text?: string | unknown
  pageCount?: number | unknown
}

export const sProcessPreLogin = selector<ProcessPreLoginSelector>({
  key: "sProcessPreLogin",
  get: ({ get }) => ({
    apaInitAuthToken: get(aBearerToken),
    appName: get(aPreloginAppName),
    providerName: get(aPreloginProviderName),
    redirectUrl: get(aRedirectUrl) || "",
    operationType: get(aOperationType),
    document: get(aDocumentToSign),
    signObject: get(aObjectToSign),
    signRequestId: get(aSignRequestId),
  }),
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      return
    }

    const {
      apaInitAuthToken,
      appName,
      providerName,
      redirectUrl,
      operationType,
      document,
      signObject,
      signRequestId,
    } = value

    let mergedDocument: DocumentToSign = {}

    if (document) {
      mergedDocument = { ...document }
      const documentTitle: DocumentTitle = {}

      if (document.uri) {
        documentTitle.uri = document.uri
      }

      if (document.title) {
        documentTitle.text = document.title
      }

      if (document.page_count) {
        documentTitle.pageCount = document.page_count
      }

      mergedDocument.title = documentTitle
    }

    mergedDocument.appName = signObject ? appName : null
    mergedDocument.providerName = providerName
    mergedDocument.signRequestId = signRequestId

    let mergedSignObject: ObjectToSign = {}
    if (signObject) {
      mergedSignObject = { ...signObject }

      if (signRequestId) {
        mergedSignObject.signRequestId = signRequestId
      }
    }

    set(aBearerToken, apaInitAuthToken)
    set(aQrBearerToken, apaInitAuthToken)
    set(aRedirectUrl, redirectUrl)
    set(aPreloginAppName, appName)
    set(aPreloginProviderName, providerName)
    set(aPhase, "qrInit")
    set(aOperationType, operationType)
    set(aDocumentToSign, mergedDocument)
    set(aObjectToSign, mergedSignObject)
    set(aSignRequestId, signRequestId)
  },
})

type FinishAuthenticationSelector = {
  bearerToken: BearerToken
  phase: PhaseState
  redirectUrl: string | undefined
}

export const sFinishAuthentication = selector<FinishAuthenticationSelector>({
  key: "sFinishAuthentication",
  get: ({ get }) => ({
    bearerToken: get(aBearerToken),
    phase: get(aPhase),
    redirectUrl: get(aRedirectUrl),
  }),
  set: ({ set, reset }, value) => {
    if (value instanceof DefaultValue) {
      return
    }

    const { bearerToken, phase, redirectUrl } = value
    set(aBearerToken, bearerToken)
    set(aPhase, phase)
    set(aRedirectUrl, redirectUrl)
    reset(aLoginStep)
  },
})

type HandleLoginErrorSelector = {
  loginPhaseError: LoginPhaseError
  ibUrl?: string
}

export const sHandleLoginError = selector<HandleLoginErrorSelector>({
  key: "sHandleLoginError",
  get: ({ get }) => ({
    loginPhaseError: get(aLoginPhaseError),
    ibUrl: get(aIbUrl),
  }),
  set: ({ set, reset }, value) => {
    if (value instanceof DefaultValue) {
      return
    }

    const { loginPhaseError, ibUrl } = value

    set(aLoginPhaseError, loginPhaseError)
    set(aPhase, "error")
    reset(aLoginStep)

    if (ibUrl) {
      set(aIbUrl, ibUrl)
    }
  },
})
