import React, { FC, useEffect } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useLocation } from "react-use"

import { IdPortError } from "../../state/idport"

import Button from "../../components/ui/button"
import Footer from "../../components/footer/footer"
import GlobalErrorForbidden from "../../components/global-error/forbidden"
import GlobalErrorGeneral from "../../components/global-error/general"
import GlobalErrorTimeout from "../../components/global-error/timeout"
import Header from "../../components/header/header"
import RedirectForm from "../../components/redirect-form"

import styles from "./index.module.css"
import { aPhase, aUsername } from "../../state/global"
import ContactUs from "../../components/global-error/contact-us"
import { aRedirectUrl } from "../../state/login-step"

const Error: FC = () => {
  const setPhase = useSetRecoilState(aPhase)
  const { state: locationState } = useLocation()

  let errorType = locationState?.state?.errorType
  if (errorType === undefined) {
    errorType = "general"
  }

  const [username, setUsername] = useRecoilState(aUsername)
  const redirectUrl = useRecoilValue(aRedirectUrl)

  useEffect(() => {
    setPhase("error") // TODO handle phase init state on route init in app
    setUsername("")
  }, [username, setUsername, setPhase])

  let errorCode: IdPortError | "forbidden" | undefined

  switch (errorType) {
    case "timeout":
      errorCode = "temporarily_unavailable"
      break
    case "general":
      errorCode = "server_error"
      break
    case "forbidden":
      errorCode = "forbidden"
      break
    default:
      errorCode = "server_error"
      break
  }

  return (
    <>
      <section className="mx-auto max-w-5xl px-6">
        <Header />
        <div
          className={`${styles.wrapper} flex flex-col sm:block relative bg-white border border-solid border-black-da rounded-md`}
        >
          <div className="flex flex-col p-4 sm:p-10">
            {(errorType === "general" || errorType === "server") && (
              <GlobalErrorGeneral />
            )}
            {errorType === "timeout" && <GlobalErrorTimeout />}
            {errorType === "forbidden" && <GlobalErrorForbidden />}
          </div>
          <aside
            className={`${styles.form} sm:absolute sm:top-0 sm:right-0 sm:bottom-0 sm:-mt-4 sm:-mb-5 sm:mr-5 sm:ml-0 -m-px p-5 flex flex-col rounded-md bg-black-fa border border-solid border-black-b0`}
          >
            {redirectUrl ? (
              <RedirectForm
                autoSubmit={false}
                errorCode={errorCode === "forbidden" ? undefined : errorCode}
                action={errorCode === "forbidden" ? "CANCEL" : "ERROR"}
              >
                <Button
                  testId="button-try-again"
                  type="submit"
                  className="mt-10 w-full"
                >
                  Začít znovu
                </Button>
              </RedirectForm>
            ) : (
              <ContactUs />
            )}
          </aside>
        </div>
        <Footer />
      </section>
    </>
  )
}

export default Error
